import { createMuiTheme } from '@material-ui/core/styles'
import { messagesTheme } from './sub-themes/messages/MessagesTheme.js'
import pdcLogoBig from './images/pdc-logo.svg'
import ghostPhoneComIcon from './images/ghost-phone-com-icon.svg'
import appLoaderDesktop from './images/pdc-logo-big.svg'
import appLoaderMobile from './images/pdc-logo-small.svg'
import './css/FontImport.css'
const drawerWidth = 240
const appBarHeight = 56

const defaultColors = {
	greenBrandStandard:	'#3fae29',
	greenBrandTint:		'#7fcd71',
	greenBrandLiteTint:	'#b3deab',
	greenBrandTint3:	'#d9efd4',
	chatBubbleGrn:		'#d9efd4',
	chatBubbleGray:		'#ebeff2',
	chatBubbleError:	'#f6bbb5',
	alertColor:			'#e85646',
	red:				'#f03622',
	gray:				'#5a666d',
	grayBright:			'#f2f5f6',
	grayDark:			'#364047',
	grayLite:			'#88969e',
	grayXLite:			'#b6c0c5',
	grayXXLite:			'#ccd6db',
	grayXXXLite:		'#e6ebee',
	black:				'#000',
	greenDark:			'#238a12',
	greenLight:			'#5ec349',
	orange:				'#f88b01'
}

const primaryColor				= defaultColors.greenBrandStandard
const primaryLightColor			= '#c5efbd'
const primaryFlatBorderColor	= '#ccd6db'

const errorColor = '#F9D628'
const tertiaryColor = '#364047'

const mainTheme = createMuiTheme({
	appName: 'My Phone.com',
	notificationIconName: 'icon192.png',
	fontFamily: 'ProximaNova, Helvetica, arial, sans-serif !important',
	notificationDotColor: defaultColors.orange,
	palette: {
		primary: {
			main:					primaryColor,
			brandColor:				primaryColor,
			light:					primaryLightColor,
			background:				'#fff',
			borderColor:			primaryFlatBorderColor,
			flatBorder:				`1px solid ${primaryFlatBorderColor}`,
			flatBottomShadow:		`inset 0px -1px 0px 0px ${primaryFlatBorderColor}`,
			flatRightShadow:		`0 0 0 1px ${primaryFlatBorderColor}`,
			flatLeftShadow:			`0 1px 0 0 ${primaryFlatBorderColor}`,
			flatTopShadow:			`1px 0 0 0 ${primaryFlatBorderColor}`,
			borderRadiusSmall:		7, // for small items like menu options
			borderRadiusLarge:		15, // for large window popups
			boxShadow:				'5px 5px 0 0 rgba(0, 0, 0, 0.4)',
			boxShadowColorless:		'5px 5px 0 0',
			disabledButtonColor:	'#c9c9c9',
			selectedContentColor:	'#ddd',
			onHoverColor:			'#f2f5f6',
			dark: 					'#238a12'
		}, // Purple and green play nicely together.
		secondary: { main: '#FA9927' },
		tertiary: {
			xxlite: defaultColors.grayXXLite,
			lite: defaultColors.grayLite,
			main: defaultColors.gray,
			dark: tertiaryColor
		},
		error: {
			main: errorColor,
			flatBorder: `1px solid ${errorColor}`
		}
	},
	notifications:{
		inAppNotificationColor: '#000000',
		inAppNotificationTextColor: '#FFFFFF',
	},
	screenViewSizes: {
		mobileViewSize: 600,
		tabletViewSize: 1000,
	},
	drawerWidth: drawerWidth,
	appBarHeight: appBarHeight,
	appBar: {
		width:				'100%',
		height:				`${appBarHeight}px`,
		backgroundColor:	tertiaryColor,
		color:				'#fff',
		position:			'absolute'
	},
	drawerUserInfo: {
		extNumberLineHeight: 0.8,
		extNumberColor: '#88969e',
		extNameFontSize:14,
		extNumberFontSize:22,
		extNumberFontSizeSmall:18,
		backgroundColor: tertiaryColor,
		textColor: '#fff',
		onHoverShade: 'rgba(0, 0, 0, 0.3)',
		extButtonColorHover: defaultColors.greenBrandStandard,
		extButtonColor: defaultColors.grayXLite
	},
	drawerPaper: {
		background: tertiaryColor,
		width: drawerWidth,
		color: "#fff",
		overflow: 'visible',
		position: 'absolute'
	},
	infoPopup: {
		backgroundColor:	tertiaryColor, // pull from theme in the future
		color:				'#ffffff'
	},
	dropdownMenu: {
		paper: {
			borderWidth:	2,
			borderStyle:	'solid',
			borderColor:	defaultColors.black,
			borderRadius:	7,
			boxShadow:		'5px 5px 0 0 rgba(0, 0, 0, 0.4)',
			minWidth:		137
		},
		list: {
			padding: '5px 0',
			item: {
				fontSize:		14,
				fontWeight:		600,
				lineHeight:		'18px',
				padding:		'8px 13px',
				'&:hover': {
					color:				defaultColors.greenBrandStandard,
					backgroundColor:	defaultColors.grayBright
				}
			}
		},
	},
	confirmDialog: {
		paper: {
			padding:		'40px 50px',
			borderRadius:	10,
			'&.size1': {
				maxWidth:	330
			},
			'&.size2': {
				maxWidth:	400
			}
		},
		dialogTitle: {
			padding: 0,
			'& h2': {
				fontSize:		22,
				fontWeight:		500,
				lineHeight:		1.05,
				letterSpacing:	-0.3,
				color:			defaultColors.black
			}
		},
		dialogContent: {
			fontSize:		16,
			fontWeight:		500,
			lineHeight:		1.31,
			letterSpacing:	-0.1,
			margin:			'15px 0 20px',
			padding:		0,
			color:			defaultColors.gray
		},
		dialogFooter: {
			padding:	0,
			textAlign:	'right',
			'& img': {
				width: '100%'
			}
		},
		acknowledgeDeleteColor: defaultColors.gray
	},
	selector: {
		width:'30%',
		selectorItem: {
			fontSize:		14,
			background:		'#fff',
			minHeight:		70,
			cursor:			'pointer',
			margin:			5,
			borderRadius:	3,
			'&:hover': {
				backgroundColor:	defaultColors.grayBright
			},
			'&.selected-item': {
				backgroundColor:	defaultColors.grayXXXLite
			},
			'& .main-view': {
				padding:	'15px',
				display:	'flex',
				position:	'relative'
			}
		}
	},
	messagesApp: {
		app: messagesTheme,
		profileImageColorList: ["#F03622", "#F66363", "#F88B01", "#FBBF00", "#8AC917", "#5A9217", "#40C7CF", "#478BFF", "#1D5ECC", "#8F0099"],
		profileGroupImageColor: '#7E828E',
		startConversation: {
			textColor: primaryColor,
			hoverColor: primaryLightColor,
		},
		participantsPopOut: {
			headerBackground: primaryColor,
			headerTextColor: '#fff',
			youTextColor: primaryColor
		},
		contentItems: {
			inBoundContentItemBackgroundColor:	defaultColors.chatBubbleGray,
			outBoundContentItemBackgroundColor:	defaultColors.chatBubbleGrn,
			contentItemBackgroundError:			defaultColors.chatBubbleError,
			errorMessageColor:					defaultColors.red,
			itemInfoBarColor:					defaultColors.gray,
			itemText:							defaultColors.grayDark,
			itemMenuButton:						defaultColors.grayXLite,
			itemMenuButtonActive:				defaultColors.black,
			thumbnailImageColor:				defaultColors.grayDark,
			dateSectionColor:					defaultColors.gray,
			timeSectionColor:					defaultColors.black,
			senderSectionColor:					defaultColors.grayLite
		},
		sendMessage: {
			mediaContainerBorderColor:			defaultColors.grayXXLite,
			mediaContainerBackgroundColor:		'transparent'
		},
		mediaModal: {
			infoValueColor:						defaultColors.grayLite,
			dividerColor:						defaultColors.grayXXLite,
			mediaGalleryBackgroundColor:		defaultColors.grayDark
		},
		conversationSelector: {
			timeColor: defaultColors.gray
		}
	},
	tinyText: {
		fontSize: '11px'
	},
	disabledLabel: {
		color: primaryColor
	},
	loadingDiv: {
		position:	'absolute',
		left:		'0',
		right:		'0',
		top:		'0',
		bottom:		'0',
		background:	'rgba(255, 255, 255, 0.5)',
		zIndex:		'2',
		cursor:		'progress'
	},
	loaderFull: {
		position:	'absolute',
		top:		'50%',
		left:		'50%',
		transform:	'translate(-50%, -50%)'
	},
	spinnerColor:	defaultColors.greenBrandStandard,
	loginApp: {
        inputsWrapper: {
            border: '1px solid lightgray',
            borderRadius: 8,
            padding: '6px 30px',
            background: 'white'
        },
        inputWrapper: {
            position: 'relative',
            height: 54,
            '& input': {
                position: 'absolute',
                color: '#171717',
                background: 'transparent',
                width: '100%',
                height: '100%',
                border: 'none',
                padding: '16px 0',
                outline: 'none',
                fontSize: 18,
                zIndex: 1,
                '&.error': {
                    color: 'red'
                }
            }
        },
        inputLabel: {
            color: '#b1b1b1',
            position: 'absolute',
            left: 0,
            top: 18,
            fontSize: 18,
            fontStyle: 'italic',
            fontWeight: 500,
            lineHeight: 1,
            userSelect: 'none',
            cursor: 'text',
            transition: 'top .2s, font-size .2s, font-style .2s',
            '&.up': {
                top: 3,
                fontSize: 12,
                fontStyle: 'normal'
            },
            '&.error': {
                color: 'red'
            }
        },
        buttonsWrapper: {
            marginTop: 25,
            display: 'flex',
            justifyContent: 'center'
        },
        switchViewLink: {
            marginTop: 15,
            textAlign: 'center',
            '& span': {
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                    color: '#0066e4'
                }
            }
        },
    },
	readStatusButton: {
		cursor: 'pointer',
		'&:hover': {
			'& .mark-read, & .mark-unread': {
				display: 'none'
			}
		},
		'&:not(:hover)': {
			'& .mark-read-hover, & .mark-unread-hover': {
				display: 'none'
			}
		}
	},
	vmFaxHeaderItem: {
		fontSize:		16,
		padding:		'0 10px',
		lineHeight:		1.31,
		letterSpacing:	-0.1,
		color:			'black',
		'&:not(:last-child)': {
			borderRight:		'1px solid',
			borderRightColor:	defaultColors.grayXXLite
		},
		'&:first-child': {
			paddingLeft: 0
		},
		'&:last-child': {
			paddingRight: 0
		}
	},
	faxesApp: {
		infoBorderColor:		defaultColors.grayXXLite,
		infoColor:				defaultColors.black,
		conteactInfoColor:		defaultColors.grayLite,
		headerSeparatorColor:	defaultColors.grayXXLite,
		newFaxPanel: {
			buttonDescriptionColor:		defaultColors.grayDark,
			separatorColor:				defaultColors.black,
			dropAreaBorderColor:		defaultColors.greenBrandTint,
			dropAreaBackgroundColor:	'rgba(230, 235, 238, 0.5)',
			dropAreaTextColor:			defaultColors.grayDark,
			uploadFilenameColor:		defaultColors.gray,
			pdfFilePagesColor:			defaultColors.grayDark,
			progressBar: {
				backgroundColor: defaultColors.grayXXXLite
			}
		},
		tabs: {
			defaultTab:		'sent',
			firstTabName:	'sent',
			secondTabName:	'received',
			firstTabColor:	defaultColors.greenBrandStandard,
			secondTabColor:	defaultColors.grayLite
		},
		noSetup: {
			title:		'Ooops! You currently do not have a fax number connected to your extension.',
			linkText:	'Learn how to connect your fax number',
			link:		'https://www.phone.com/phone-support/receiving-faxes/'
		},
		noSentFaxMessage: 'You haven\'t sent any faxes'
	},
	webButton: {
		padding:			'5px 20px 5px 20px',
		color:				'white',
		backgroundColor:	'#0840a1',
		border:				'1px solid transparent',
		borderRadius:		6,
		cursor:				'pointer',
		fontWeight:			'bold',
		'&.not-allowed': {
			color:			'gray',
			background:		'white',
			borderColor:	'gray',
			fontWeight:		'initial',
			cursor:			'not-allowed'
		}
	},
	settingsApp: {
		header: {
			height:			50,
			boxShadow:		'inset 0px -1px 0px 0px #ccd6db',
			padding:		'0 15px',
			display:		'flex',
			justifyContent:	'space-between',
			alignItems:		'center',
			'& .header-title': {
				marginLeft:	15,
				fontSize:	20,
				fontWeight:	500
			}
		},
		settingWrapper: {
			minHeight:	0,
			padding:	'50px 25px',
			flex:		1,
			height:		'100%',
			'&.small-view': {
				padding: 10
			}
		},
		selectorItem: {
			color: defaultColors.grayDark,
			selectedBackgroundColor: defaultColors.grayXXXLite,
			hoverBackgroundColor: defaultColors.grayBright
		},
		plan: {
			priceColor: defaultColors.grayDark,
			disclaimerColor: defaultColors.gray
		}
	},
	audioPlayer: {
		progressFillColor:		defaultColors.gray,
		controlSlider: {
			defaultColor:		defaultColors.greenBrandStandard,
			lightGradientColor:	defaultColors.greenLight,
			darkGradientColor:	defaultColors.greenDark
		}
	},
	icons: {
		logo:		pdcLogoBig,
		ghostIcon:	ghostPhoneComIcon,
		smallLogo:	appLoaderMobile
	},
	svgIcons:{
		voicemailNavIcon:			{main:primaryColor, lite:'#7BCF6A', xlite:'#D9EFD4'},
		removePillBackgroundColor:	defaultColors.chatBubbleGrn,
		circleShadowFe:				'0 0 0 0 0.136137293 0 0 0 0 0.542544158 0 0 0 0 0.0698753041 0 0 0 1 0'
	},
	navigation: {
		ghostIcon: {
			zIndex:		1200,
			width:		'fit-content',
			padding:	15,
			opacity:	0.25,
			content:	`url(${ghostPhoneComIcon})`
		}
	},
	favicon: {
		default:	'https://www.phone.com/favicon.ico',
		unread:		'/icons/favicon-unread.ico'
	},
	titles: { /* MainTheme's default title is 'My Phone.com' */},
	notificationBar: {
		backgroundColor: defaultColors.orange
	},
	appLoader: {
		backgroundColor:	'white',
		logo: {
			width:			150,
			height:			150,
			background:		`url(${appLoaderMobile}) no-repeat`,
			backgroundSize:	'contain'
		},
		progressBar: {
			backgroundColor:	defaultColors.greenBrandTint3,
			fillColor:			defaultColors.greenBrandStandard
		},
		loadingText: {
			fontSize:		16,
			fontWeight:		500,
			lineHeight:		1.31,
			letterSpacing:	-0.1,
			color:			defaultColors.grayLite,
			marginTop:		21,
			'& .name': {
				color:		defaultColors.greenBrandStandard,
				fontWeight:	'bold'
			}
		}
	},
	appsTabLink:	'https://www.phone.com/features/mobile_office/',
	supportTabLink:	'https://www.phone.com/support/'
})

export { mainTheme }